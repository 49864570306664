// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aktuelles-corona-jsx": () => import("./../../../src/pages/aktuelles/corona.jsx" /* webpackChunkName: "component---src-pages-aktuelles-corona-jsx" */),
  "component---src-pages-aktuelles-eroeffnung-jsx": () => import("./../../../src/pages/aktuelles/eroeffnung.jsx" /* webpackChunkName: "component---src-pages-aktuelles-eroeffnung-jsx" */),
  "component---src-pages-aktuelles-index-jsx": () => import("./../../../src/pages/aktuelles/index.jsx" /* webpackChunkName: "component---src-pages-aktuelles-index-jsx" */),
  "component---src-pages-aktuelles-vfl-jsx": () => import("./../../../src/pages/aktuelles/vfl.jsx" /* webpackChunkName: "component---src-pages-aktuelles-vfl-jsx" */),
  "component---src-pages-behandlung-jsx": () => import("./../../../src/pages/behandlung.jsx" /* webpackChunkName: "component---src-pages-behandlung-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-diagnostik-jsx": () => import("./../../../src/pages/diagnostik.jsx" /* webpackChunkName: "component---src-pages-diagnostik-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-praxis-dr-burkhard-schubert-jsx": () => import("./../../../src/pages/praxis/dr-burkhard-schubert.jsx" /* webpackChunkName: "component---src-pages-praxis-dr-burkhard-schubert-jsx" */),
  "component---src-pages-praxis-dr-kathrin-kostka-jsx": () => import("./../../../src/pages/praxis/dr-kathrin-kostka.jsx" /* webpackChunkName: "component---src-pages-praxis-dr-kathrin-kostka-jsx" */),
  "component---src-pages-praxis-index-jsx": () => import("./../../../src/pages/praxis/index.jsx" /* webpackChunkName: "component---src-pages-praxis-index-jsx" */),
  "component---src-pages-therapien-innovativ-jsx": () => import("./../../../src/pages/therapien/innovativ.jsx" /* webpackChunkName: "component---src-pages-therapien-innovativ-jsx" */),
  "component---src-pages-therapien-integrativ-jsx": () => import("./../../../src/pages/therapien/integrativ.jsx" /* webpackChunkName: "component---src-pages-therapien-integrativ-jsx" */),
  "component---src-pages-therapien-klassisch-jsx": () => import("./../../../src/pages/therapien/klassisch.jsx" /* webpackChunkName: "component---src-pages-therapien-klassisch-jsx" */)
}

